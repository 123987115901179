import React, { useState, useEffect } from "react";

//import Login from "./components/Login";
//import CheckToken from "./components/CheckToken";
import Main from "./components/Main";

import "./App.css";

import Cookies from "universal-cookie";

function App() {
  const cookies = new Cookies();

  const [isAutf, setIsAutf] = useState("0");
  const [checkTk, setCheckTk] = useState("0");
  const [colorTheme, setColorTheme] = useState("#1976d2");

  useEffect(() => {
    if (typeof cookies.get("tk") !== "undefined") {
      setCheckTk("1");
    }
  }, []);

  useEffect(() => {
    //console.log("App --> isAutf= " + isAutf);
    //console.log("App --> checkTk= " + checkTk);
  }, [isAutf, checkTk]);

  return (
    <div className="App">
      <Main
        setIsAutf={setIsAutf}
        colorTheme={colorTheme}
        setColorTheme={setColorTheme}
      />
    </div>
  );
}

/*
 <>
          {isAutf === "1" ? (
            <Main
              setIsAutf={setIsAutf}
              colorTheme={colorTheme}
              setColorTheme={setColorTheme}
            />
          ) : (
            <Login
              isAutf={isAutf}
              setIsAutf={setIsAutf}
              setColorTheme={setColorTheme}
            />
          )}
        </>
*/

export default App;
