import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import "../App.css";
//import logo from "../images/logo_blue.png";

const useStyles = makeStyles((theme) => ({}));

export default function Main(props) {
  const classes = useStyles();

  return (
    <div className="login-layer-limit">
      <div className="login-layer-background">
        <Grid container direction="column" alignItems="center" justify="center">
          <Grid item>
            <img
              src="http://ezprojectorganizer.com/images/logo_blue.png"
              style={{ width: 250, height: 250, marginBottom: 20 }}
            />
          </Grid>
          <Grid item>
            <Typography
              variant="h3"
              style={{
                color: "#2389a8",
                fontWeight: "bold",
                fontStyle: "italic",
              }}
            >
              EZ Project Organizer
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
